import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";
import { useEffect } from "react";
import axios from "axios";

const Restatebrokerage = () => {
  const [activeIndex, setActiveIndex] = useState(null); // Track the active div
  const [pageTitle, setpageTitle] = useState("");
  const [desc1, setpageDesc1] = useState("");
  const [alldata, setAlldata] = useState([]);
  const [serviceDetails, setServicedetails] = useState([]);
  const [service_desc, setServiceDesc] = useState("");
  const [isVisible, setIsVisible] = useState("");

    
  ////Detect laanguage from local storage on 18.11.2024
  const detectLan = localStorage.getItem('detect_inner_lang_val');
  console.log("Restate page value from local storage", detectLan);
  

    const fetchLang = useSelector(state => state.favorites.language);
    //console.log("Fetch language in Resatate", fetchLang);
    const getLanguage = fetchLang;
    const languageidVal = (fetchLang=='English') ? "1":"2";
    const data = {
         "service_slug": 'real-estate-brokerage',
         "language_id": languageidVal
    }

    //const apiUrl = '/services-show-by-slug';
    //const apiUrl = 'https://sisdeve.com/dev/altayar/services-show-by-slug';
    const apiUrl = 'https://altayarproperties.om/admin/services-show-by-slug';
    useEffect(() => {
      // Define the async function inside the useEffect
      const postData = async () => {
        try {
          const response = await axios.post(apiUrl, {
            "service_slug": 'real-estate-brokerage',
            "language_id": languageidVal
          });
          //console.log("API Response:", response.data);
          const alldata =  response.data;
          setAlldata(response.data);
          const pageTitle = alldata.submenu_details?.submenu_name;
          setpageTitle(pageTitle);
          //console.log("Page title", pageTitle);
          const desc1 = alldata.submenu_details?.submenu_description_1;
          setpageDesc1(desc1);

          const serviceDetails = alldata?.submenu_details?.service_details;
          //console.log("Service details", serviceDetails);
          setServicedetails(serviceDetails);
          const getDefaultDesc_Pro_sale = serviceDetails[2].service_description;
          //console.log("Deafult1111", getDefaultDesc_Pro_sale);
          setServiceDesc(getDefaultDesc_Pro_sale);
          const getDefaultServiceID = serviceDetails[2].service_id;


        } catch (error) {
          console.error("Error during API call:", error);
        }
      };
  
      // Call the function to execute the POST request
      postData();
    }, [languageidVal]); // Empty dependency array ensures the API call runs only once when the component mounts

 
    //Function to toggle active class for a particular div
     const toggleActive = (index, service_desc) => {
      //console.log("Active index", index);
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle active state
      //console.log("Service dec", service_desc);
      setServiceDesc(service_desc);
      setIsVisible(false);

    };

    const stripTags = (str) => {
      return str.replace(/<\/?[^>]+(>|$)/g, "");
    };


  // fetch Haedding class from store
  const getHaeding = useSelector((state) => state.favorites.headding);
  //console.log("Heading classs in Restate page===========>", getHaeding);
  const getSubheading = useSelector((state) => state.favorites.subheadding);
  //console.log("Subheading", getSubheading);
  const getParagraph = useSelector(state => state.favorites.paragraph);
  //console.log("Paragraph", getParagraph);

      return (
        <>
          <main>
            {/* banner section start */}
            <section className="banner inn">
              <div
                className="banner_bg"
                style={{ backgroundImage: "url(images/details_bg.jpg)" }}
              >
                <h2 className={`${fetchLang == "English" ?"": getHaeding}`}>{pageTitle}</h2>
              </div>
            </section>
            {/* banner section end */}
            <section className="card_section_marketing py-5">
              <div className="container">
                <div className="row">
                  <p className={`${fetchLang == "English" ?"": getParagraph}`}>
                    {desc1}
                  </p>
                </div>

                <div className="row">
                  {serviceDetails?.map(serviceList=>(
                        <div className="col-md-3" key={serviceList.service_id}>
                            {/* <div
                            className={`card_box tab-button ${activeIndex == serviceList.service_id && serviceList.service_id==17 ? "active" : ""}`}
                            onClick={() => toggleActive(serviceList.service_id, serviceList?.service_description)} // Toggle active state on click
                          > */}

                          <div
                            className={`card_box tab-button ${serviceList.service_id==activeIndex ? "active" : ""}`}
                            onClick={() => toggleActive(serviceList.service_id, serviceList?.service_description)} // Toggle active state on click
                          >
                            <div className="img_box">
                              <img src={serviceList?.service_icon} alt="" />
                            </div>
                            <h4 className={`${fetchLang == "English" ?"": getHaeding}`}>{serviceList?.service_title}</h4>
                          </div>
                        </div>
                  ))}
                </div>
                 

                


                <div id={`tab${activeIndex}`} className="tab-content in" style={{ display:"block"}}>
                   {/* {service_desc} */}
                   <div dangerouslySetInnerHTML={{ __html: service_desc }} />

                </div>


              </div>
            </section>
            {/* cards section start */}
            {/* cards section end */}
          </main>
    
      </>
      
      )
}
export default Restatebrokerage;