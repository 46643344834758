import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Middle() {
    const [searchQuery, setSearchQuery] = useState(''); // State to store the search query
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        //console.log("Get value", searchQuery);
        //e.preventDefault();
        try {
            //const apiUrl = '/home/search';
            //const apiUrl = 'https://sisdeve.com/dev/altayar/home/search';
            const apiUrl = 'https://altayarproperties.om/admin/home/search';
            const response = await axios.post(apiUrl, {
                //item_name:"place"
                item_name: searchQuery
            });
            //console.log("Search response", response.data);
            //console.log(response.data, "data");
            navigate(`/listing`, { state: { searchQuery } });
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    ///Enter hit button
    const handleKeyDown = (event) => {
        ///event.preventDefault();
        //console.log("Taget value", event.target.value);
        //console.log("Key Val", event.key);
        if(event.key=='Enter'){
            event.preventDefault(); // Prevent any default behavior if necessary
            handleSubmit();
        }
          
    }

    // Apartment of Oman 2
    return (
        <main>
            <section className="main_body">
                <div className="container">
                    <div className="row">
                        <div className="logo_big">
                            <img src="images/logo.png" alt="" />
                        </div>
                        <div className="search_bar">
                            <input type="text" value={searchQuery} onChange={handleInputChange} onKeyDown={handleKeyDown} />
                            <span onClick={handleSubmit}>
                                <i className="fa-solid fa-magnifying-glass" />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Middle;
