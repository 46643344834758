import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import "swiper/swiper-bundle.min.css";
import { useParams } from "react-router-dom";
import { register } from "swiper/element/bundle";
register();



function DetailsSec() {
  
  const fetchLang = useSelector(state => state.favorites.language);
  console.log("Fetch language in detail page", fetchLang);
  const getLanguage = fetchLang;
  const languageidVal = (fetchLang=='English') ? "1":"2";
  //convert Arabic data
  const ret_search = (fetchLang=='English') ? "Return to Search" : "العودة إلى البحث";
  const omr = (fetchLang=='English') ? "OMR" : "ريال عماني";
  const month = (fetchLang=='English') ? "month" : "شهر";
  const replylist = (fetchLang=='English') ? "Reply to the listing" : "الرد على القائمة";
  const name = (fetchLang=='English') ? "Your Name" : "اسمك";
  const email = (fetchLang=='English') ? "Email" : "بريد إلكتروني";
  const phone = (fetchLang=='English') ? "Phone" : "هاتف";
  const message = (fetchLang=='English') ? "Your message" : "رسالتك";
  const send = (fetchLang=='English') ? "Send" : "يرسل";
  const organ_name = (fetchLang=='English') ? "Organization:" : "منظمة:";
  const all_by = (fetchLang=='English') ? "All by" : "كل شيء عن طريق";
  const rent_price = (fetchLang=='English') ? "Rent Price: OMR" : "سعر الإيجار: ريال عماني";
  const details = (fetchLang=='English') ? "Details" : "تفاصيل";
  const consit_text = (fetchLang=='English') ? "Consist of:" : "تتكون من:";
  const features_text = (fetchLang=='English') ? "Features" : "سمات";
  
  const sale_rent = (fetchLang=='English') ? "Sale/Rent:" : "بيع/إيجار:";
  const sale_rent_for = (fetchLang=='English') ? "For" : "ل";
  const ptype = (fetchLang=='English') ? "Property type:" : "نوع العقار:";
  const unit_type = (fetchLang=='English') ? "Unit Type:" : "نوع الوحدة:";
  const pro_location = (fetchLang=='English') ? "Location:" : "موقع:";
  const Residential_villa = (fetchLang=='English') ? "Residential - Villa" : "سكني - فيلا"
  



  const location = useLocation();
  //const id = location.pathname.split("/")[1];
  const { id } = useParams();
  //console.log("Item ID fetch", id);
  const [itemName, setItemName] = useState("");
  const [itemdesc, setItemDesc] = useState("");
  const [itemtype, setitemtype] = useState("");
  const [itemCat, setitemCat] = useState("");
  const [itrmlocation, setlocation] = useState("");
  const [itempriceVal, setItemPrice] = useState("");
  const [userPhoneVal, setUserPhone] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [agentNameVal, setAgentName] = useState("");
  const [agentImageVal, setAgentImage] = useState("");
  const [userorganisationVal, setUserOrganasitaion] = useState("");

  const [featureList, setFeatureList] = useState([]);
  const [floordetails, setFloorDetails] = useState([]);
  const [itemImageList, setitemImageList] = useState([]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  

  const itemdetails = async (e) => {
    try {
      //const apiUrl = '/item-details';
      //const apiUrl = 'https://sisdeve.com/dev/altayar/item-details';
      const apiUrl = 'https://altayarproperties.om/admin/item-details';
      const response = await axios.post(apiUrl, {
        property_id: id,
        language_id: languageidVal
      });

      let propertyData = response.data;
      console.log("Item detail response", propertyData.data.property[0].id);
      //console.log("Item detail Image", propertyData.data.property[0].image);
      let itemImagedataVal = propertyData.data.property[0].image;
      setitemImageList(itemImagedataVal);

      let itemName = propertyData.data.property[0].item_name;
      setItemName(itemName);
      let itemDesc = propertyData.data.property[0].item_descrip;
      setItemDesc(itemDesc);
      let itemtype = propertyData.data.property[0].type_name;
      setitemtype(itemtype);
      let itemCat = propertyData.data.property[0].cat_name;
      setitemCat(itemCat);
      let itrmlocation = propertyData.data.property[0].address;
      setlocation(itrmlocation);
      let itemPrice = propertyData.data.property[0].price;
      setItemPrice(itemPrice);
      let userPhone = propertyData.data.property[0].user_phone;
      setUserPhone(userPhone);
      let agentEmail = propertyData.data.property[0].user_email;
      setAgentEmail(agentEmail);
      let agentName = propertyData.data.property[0].user_name;
      setAgentName(agentName);
      let agentImage = propertyData.data.property[0].user_image;
      setAgentImage(agentImage);

      let organizationName = propertyData.data.property[0].user_organization;
      setUserOrganasitaion(organizationName);

      let features = propertyData.data.property[0].features_list;
      console.log("Features", features);
      setFeatureList(features);

      ///Get Floor details
      let floordetailsList = propertyData.data.property[0].floor_details_list;
      console.log("Floor details List", floordetails);
      setFloorDetails(floordetailsList);
      //let floorDetails = propertyData.data.property[0].floor_details_list.floor_details;
      //console.log("Flllor details", floorDetails);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    itemdetails();
  }, [id,languageidVal]);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        //const apiUrl = '/item-details';
        //const apiUrl = 'https://sisdeve.com/dev/altayar/item-details';
        const apiUrl = 'https://altayarproperties.om/admin/item-details';
        const response = await axios.post(apiUrl, {
          property_id: id,
          language_id: languageidVal
        });
        //const result = await response.json();
        //console.log("All response", response?.data?.data?.property?.[0].image);
        setData(response);
        setLoading(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id,languageidVal]);

  const imgData = data?.data?.data?.property?.[0]?.image;
  console.log("imgData", imgData);


  

  const [formData, setFormData] = useState({
    item_id: id,
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const isFormValid =
      !!formData.name &&
      !!formData.email &&
      !!formData.phone &&
      !!formData.message;
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value.trim() });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);

    if (!formValid) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill out all required fields",
      });
      return;
    }

    try {
      console.log(formData);
      //const apiUrl_itemenquiry = 'https://sisdeve.com/dev/altayar/item-enquiry';
      const apiUrl_itemenquiry = 'https://altayarproperties.om/admin/item-enquiry';
      const response = await axios.post(apiUrl_itemenquiry, formData);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Thank You!", // Assuming the response contains a 'message' field
      });

      // Reset form fields after submission
      setFormData({
        id: id,
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });

      setFormData({
        id: id,
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      // Handle error
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong. Please try again later.",
      });
    }
  };

  return (
    <>
      <section className="prop_details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-9">
              <ul className="top_nav">
                <li>
                  <a href="#/listing" className={`${fetchLang == "English" ?"": "_paragraph"}`}>{ret_search}</a>
                </li>
              </ul>
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                {/* Amazing Standalone Villa for Rent in Al Azaiba North | REF 349MB */}
                {itemName}
              </p>
            </div>
            <div className="col-lg-4 col-md-3">
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="https://x.com/i/flow/login" target="_blank">
                    <i className="fa-brands fa-x-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i className="fa-brands fa-linkedin-in" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row top_row">
            <div className="col-lg-9 col-md-8" dir="ltr">
              {/* <div className="swiper-container">
                <div className="swiper mySwiper2">
                  <div className="swiper-wrapper">
                    <swiper-container
                      thumbs-swiper=".my-thumbs"
                      loop="true"
                      navigation="true"
                    >
                      {imgData?.map((items, index) => {
                        return (
                          <>
                            <swiper-slide key={index}>
                              <div className="main-product">
                                <img
                                  src={items.image_url}
                                  className="img-fluid"
                                />
                              </div>
                            </swiper-slide>
                          </>
                        );
                      })}
                    </swiper-container>
                  </div>
                </div>

                <div thumbsslider="" className="swiper mySwiper mt-4">
                  <div className="swiper-wrapper">
                    {imgData?.map((items, index) => {
                      return (
                        <>
                          <swiper-slide key={index}>
                            <div className="main-product">
                              <img
                                src={items.image_url}
                                className="img-fluid"
                              />
                            </div>
                          </swiper-slide>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div> */}

              {loading ? (
                <>
                  <div className="swiper mySwiper2">
                    <swiper-container
                      thumbs-swiper=".my-thumbs"
                      loop="true"
                      navigation="true"
                    >
                      {imgData?.map((items, index) => {
                        return (
                          <>
                            <swiper-slide key={index}>
                              <div className="main-product">
                                <img
                                  src={items.image_url}
                                  className="img-fluid details_gal"
                                />
                              </div>
                            </swiper-slide>
                          </>
                        );
                      })}
                    </swiper-container>
                  </div>
                  <swiper-container
                    class="my-thumbs mt-4"
                    slides-per-view="5"
                    space-between="20"
                    loop="true"
                  >
                    {imgData?.map((items, index) => {
                      return (
                        <>
                          <swiper-slide key={index}>
                            <div className="main-product">
                              <img
                                src={items.image_url}
                                className="img-fluid"
                              />
                            </div>
                          </swiper-slide>
                        </>
                      );
                    })}
                  </swiper-container>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="card_box">
                <h6 className={`${fetchLang == "English" ?"": "_heading"}`}>{omr} {itempriceVal}/{month}</h6>
                <ul className="link">
                  <li>
                    <a href="tel:+">
                      <i className="fa-solid fa-phone" />
                      <span>{userPhoneVal}</span>
                    </a>
                  </li>
                  {/* <li>
                <a href="#"
                  ><i class="fa-solid fa-heart"></i
                  ><span>Add to favorite</span></a
                >
              </li> */}
                </ul>
              </div>
              <h4 className={`${fetchLang == "English" ?"": "_heading"}`}>{replylist}</h4>
              <form name="replylist" id="replylist" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  className={`${fetchLang == "English" ?"": "_paragraph"}`}
                  placeholder={name}
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="email"
                  name="email"
                  className={`${fetchLang == "English" ?"": "_paragraph"}`}
                  placeholder={email}
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  className={`${fetchLang == "English" ?"": "_paragraph"}`}
                  name="phone"
                  placeholder={phone}
                  value={formData.phone}
                  pattern="[0-9]*"
                  onChange={handleInputChange}
                  required
                />
                <textarea
                  className={`${fetchLang == "English" ?"": "_paragraph"}`}
                  name="message"
                  placeholder={message}
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
                <button
                  type="submit"
                  // disabled={!formValid}
                  className={`btn frm_btn ${fetchLang == "English" ?"": "_heading"}`}
                >
                  {send}
                </button>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="prop_deta pdb">
                <div className="row">
                  <div className="col-6">
                    <ul>
                      <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                        <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{sale_rent}</span>
                        {sale_rent_for} {itemtype}
                       
                      </li>
                      <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                        <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{unit_type}</span>
                        {Residential_villa}
                      </li>
                      {/* <li>
                        <img src="images/bed_.png" alt />
                        5+1
                      </li>
                      <li>
                        <img src="images/contact_book.png" alt />
                        <a href="">
                          349MB <img src="images/external-link.png" alt />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul>
                      <li>
                        <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{ptype} {itemCat}</span>
                        {/* <a href="">
                          {itemCat} <img src="images/external-link.png" alt />
                        </a> */}
                      </li>
                      <li>
                        <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{pro_location} {itrmlocation}</span>
                        {/* <a href="">
                          {itrmlocation}
                          <img src="images/external-link.png" alt />
                        </a> */}
                      </li>
                      {/* <li>
                        <img src="images/shower.png" alt />7
                      </li>
                      <li>
                        <span>Furnished:</span>
                        <a href="">
                          No <img src="images/external-link.png" alt />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="pdb feature">
                <h6  className={`top ${fetchLang == "English" ?"": "_heading"}`}>{features_text}</h6>
                <ul>
                  {featureList.map((flist, index) => (
                    <li key={index}>
                      <img src="images/next.png" alt />
                      <span className={`${fetchLang == "English" ?"": "_paragraph"}`}> {flist}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pdb details">
                <h6 className={`${fetchLang == "English" ?"": "_heading"}`}>{details}</h6>
                <div className="details_inner">
                  <h5 className={`${fetchLang == "English" ?"": "_heading"}`}>{itemName}</h5>
                  <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>{itemdesc}</p>
                  <h5 className={`const ${fetchLang == "English" ?"": "_heading"}`}>{consit_text}</h5>
                  <ul className="mega_details">
                    {floordetails.map((floorlist, index) => (
                      <li className="sub_mega" key={index}>
                        <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>{floorlist.floor_name}:</p>
                        <ul className="sub_details">
                          {floorlist.floor_details.map((fdetails) => (
                            //<li key={item}>{item}</li>
                            <li>
                              <img src="images/next.png" alt />
                              <span className={`${fetchLang == "English" ?"": "_paragraph"}`}> {fdetails}</span>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                  <span  className={`price ${fetchLang == "English" ?"": "_paragraph"}`}>
                    {rent_price} {itempriceVal} \{month}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 pro_col">
              <div className="profile">
                <img src={agentImageVal} alt />
              </div>
              <div className="pro_data">
                <h6 className={`${fetchLang == "English" ?"": "_heading"}`}>{agentNameVal}</h6>
                <ul>
                  <li>
                    <a href="tel:+">
                      <img src="images/call.png" alt />
                      {userPhoneVal}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:">
                      <img src="images/email.png" alt />
                      {agentEmail}
                    </a>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{organ_name}</span> {userorganisationVal}
                  </li>
                </ul>
                <button  className={`btn profile_btn ${fetchLang == "English" ?"": "_heading"}`}>
                  {all_by} {agentNameVal}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsSec;
