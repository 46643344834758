import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";
import { detectHeadding } from "../../actions/favoritesActions";
import { detectsubHeadding } from "../../actions/favoritesActions";
import { detectParagraph } from "../../actions/favoritesActions";


function TopHeader2({settings}) {
  const [lan, setLan] = useState(useSelector(state => state.favorites.language));
  const dispatch = useDispatch();
  const suffix = useSelector(state => state.favorites.suffix);


  ///Detect language
  const fetchLang = useSelector(state => state.favorites.language);
  const languageidVal = (fetchLang=='English') ? "1":"2";
  const phone_num = (fetchLang=='English') ? settings?.site_phone_1 : settings?.site_phone_1_arb;

   ////Detect Header class
   const [haeding, setHead] = useState(useSelector(state => state.favorites.headding));
   const headerVal = useSelector(state => state.favorites.headding);
   //console.log("Detect inner header", headerVal);

  
  useEffect(() => {
    const mainCssLink = document.getElementById("main-css");
    mainCssLink.href = (lan === 'English') ? "scss/main-ltr.css": "scss/main-rtl.css";

  }, [lan]);

  

  function toggleCSS() {
    const selectedLanguage = (lan === 'English') ? 'Arabic' : 'English';
    setLan(selectedLanguage);
    dispatch(setLanguage(selectedLanguage));
  }

  ///Set the language in Local storage
  localStorage.setItem('detect_inner_lang_val', lan);

  console.log("Top header 2 lanaguage", lan);
  const headingClass = (lan === 'English') ? '' : '_heading';
  const subheadingClass = (lan==='English') ? '' : "_subheading";
  const paragraphClass =  (lan==='English') ? '' : "_paragraph";
  useEffect(() => {
    dispatch(detectHeadding(headingClass));
    dispatch(detectsubHeadding(subheadingClass));
    dispatch(detectParagraph(paragraphClass));
    
  }, [lan]);



  return (
    <>
      <div className="header_top inn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 d-flex align-items-center">
              <ul>
                <li>
                  <a href="tel:+">
                    <img src="images/callIcon.png" alt="" />
                    <span className={`adv_btn ${fetchLang == "English" ?"": "_paragraph"}`}>{phone_num}</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/emailIcon.png" alt="" />
                    <span className={`adv_btn ${fetchLang == "English" ?"": "_paragraph"}`}>info@altayarproperties.com</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <div className="logo">
                <img src="images/logo.png" alt="" />
              </div>
            </div>
            <div className="col-md-5">
              <ul className="right_translate justify-content-end">
                <li>
                  <button 
                  id="toggleCSS"
                  onClick={toggleCSS}
                  className="lang_btn">
                    <img src="images/translation.png" alt="" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopHeader2;
