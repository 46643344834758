// languageActions.js

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const setLanguage = language => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const DETECT_LOCATION = 'DETECT_LOCATION';
export const detectPath = pathname => ({
  type: DETECT_LOCATION,
  payload: pathname,
});


export const DETECT_HEADING = 'DETECT_HEADING';
export const detectHeadding = headding => ({
  type: DETECT_HEADING,
  payload: headding,
});

export const DETECT_SUBHEADING = 'DETECT_SUBHEADING';
export const detectsubHeadding = subheadding => ({
  type: DETECT_SUBHEADING,
  payload: subheadding,
});

export const DETECT_PARAGRAPH = 'DETECT_PARAGRAPH';
export const detectParagraph = paragraph => ({
  type: DETECT_PARAGRAPH,
  payload: paragraph,
});



