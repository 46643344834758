import React from "react";
//import TopHeader2 from "../../components/TopHeader2/TopHeader2";
//import Header from "../../components/Header/Header";
//import Offcanvas from "../../components/Offcanvas/Offcanvas";
//import BackToTop from "../../components/BackToTop/BackToTap";
///import Footer from "../../components/Footer/Footer";

import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";
import { useEffect } from "react";
import axios from "axios";

function Propertymanagement() {
  const [pageTitle, setpageTitle] = useState("");
  const [desc1title1, setDesc1Title1] = useState([]);
  const [desc1Services, setDesc1Services] = useState([]);

  const [desc2title2, setDesc2Title2] = useState([]);
  const [desc2Services, setDesc2Services] = useState([]);

  const [desc3title3, setDesc3Title3] = useState("");
  const [desc3icon, setdesc3icon] = useState("");

  const [alldata, setAlldata] = useState([]);
  const [serviceDetails, setServicedetails] = useState([]);


  const fetchLang = useSelector(state => state.favorites.language);
  console.log("Fetch language in Resatate", fetchLang);
  const getLanguage = fetchLang;
  const languageidVal = (fetchLang=='English') ? "1":"2";

 ///Arbaic text conversation
 const resident = (fetchLang == 'English') ? "Resident satisfaction" : "رضا المقيمين";
 const savemoney = (fetchLang == 'English') ? "saves you money!" : "يوفر لك المال!";


  //const apiUrl = '/services-show-by-slug';
  //const apiUrl = 'https://sisdeve.com/dev/altayar/services-show-by-slug';
  const apiUrl = 'https://altayarproperties.om/admin/services-show-by-slug';

  useEffect(() => {
    // Define the async function inside the useEffect
    const postData = async () => {
      try {
        const response = await axios.post(apiUrl, {
          "service_slug": 'property-management',
          "language_id": languageidVal
        });
        console.log("API Response:", response.data);
        const alldata =  response.data;
        setAlldata(response.data);
        const pageTitle = alldata.submenu_details?.submenu_name;
        setpageTitle(pageTitle);
        //console.log("Page title", pageTitle);
        const sub_menu_desc1 = alldata.submenu_details?.submenu_description_1;
        console.log("Desc1", sub_menu_desc1.title);
        //const desc1_title1 = desc1.title;
        setDesc1Title1(sub_menu_desc1.title);

        const sub_menu_desc1_services = alldata.submenu_details?.submenu_description_1?.services;
        console.log("Desc1_seervices", sub_menu_desc1_services.service_title);
        //const desc1_title1 = desc1.title;
        setDesc1Services(sub_menu_desc1_services);
        
        
        const desc2 = alldata.submenu_details?.submenu_description_2;
        console.log("Desc1", desc2.title);
        //const desc1_title1 = desc1.title;
        setDesc2Title2(desc2?.title);
        const sub_menu_desc2_services = alldata.submenu_details?.submenu_description_2?.services;
        //console.log("Desc1_seervices", sub_menu_desc1_services.service_title);
        //const desc1_title1 = desc1.title;
        setDesc2Services(sub_menu_desc2_services);


        const desc3 = alldata.submenu_details?.submenu_description_3;
        setDesc3Title3(desc3);
        const desc3icon = alldata?.submenu_details?.submenu_icon;
        console.log("Service details", serviceDetails);
        setdesc3icon(desc3icon);


      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    // Call the function to execute the POST request
    postData();
  }, [languageidVal]); // Empty dependency array ensures the API call runs only once when the component mounts



 



    return (
        <>
    <main>
      {/* banner section start */}
      <section className="banner inn">
        <div
          className="banner_bg"
          style={{ backgroundImage: "url(images/details_bg.jpg)" }}
        >
          <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>{pageTitle}</h2>
        </div>
      </section>
      {/* banner section end */}
      {/* cards section start */}
      <section className="card_section_poperty py-5 in">
        <div className="container">
          <div className="row row_rev pp_row">
            <div className="col-md-6">
              <div className="img_box">
                <img src="./images/pm_1.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="cont_box">
                <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>{desc1title1}</h2>
                <div className="row mt-4">
                  {desc1Services?.map(serviceList=>(
                      <div className="col-md-4 col-6 mb-3">
                        <div className="card_box">
                          <div className="img_box">
                            <img src={serviceList?.service_icon} alt="" />
                          </div>
                          <div className="card_text mt-2">
                            <h3 style={{fontSize:"17px"}} className={`highlight ${fetchLang=='English' ? "" : "_heading"}`}>{serviceList?.service_title}</h3>
                          </div>
                        </div>
                      </div>
                   ))}

                  
                </div>
                
              </div>
            </div>
          </div>
          <div className="row row_rev mt-5 pp_row">
            <div className="col-md-6">
              <div className="img_box">
                <img src="./images/pm_2.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="cont_box">
                <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>{desc2title2}</h2>
                <div className="row mt-4">
                   {desc2Services?.map(servicelist2=>(
                      <div className="col-md-4 col-6 mb-3">
                        <div className="card_box">
                          <div className="img_box bg_primary">
                            <img src={servicelist2?.service_icon} alt="" />
                          </div>
                          <div className="card_text mt-2">
                            <h3 className={`highlight ${fetchLang=='English' ? "" : "_heading"}`} style={{fontSize:"17px"}}>{servicelist2?.service_title}</h3>
                          </div>
                        </div>
                      </div>
                   ))}

                  
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="container f_container">
          <div className="row">
            <div className="col-4">
              <div className="img_box">
                <img src="./images/saving_pp.png" alt="" />
                {/* <img src={desc3icon} alt="" /> */}
              </div>
            </div>
            <div className="col-8">
              <div className="bot_text">
                <p  className={`highlight text-center ${fetchLang=='English' ? "" : "_heading"}`}>
                  {resident} <br /> <span>{savemoney}</span>
                  {/* {desc3title3} */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </>

 )
}

export default Propertymanagement;