import React, { useState } from "react";
import Header from "../../components/Header/Header";
import TopHeader from "../../components/TopHeader/TopHeader";
import TopHeader2 from "../../components/TopHeader2/TopHeader2";
import Middle from "../../components/Middle/Middle";
import Offcanvas from "../../components/Offcanvas/Offcanvas";
import BackToTop from "../../components/BackToTop/BackToTap";
import Footer from "../../components/Footer/Footer";
import useFetch from "../hooks/useFetch2";
import Loading from "../../components/Loading/Loading";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";


function Home() {
  const location = useLocation();
  //console.log("Location", location.pathname)
  localStorage.setItem('setlocation', location.pathname);
    

  const { lang } = useParams();  // Get the current language from the URL
  //console.log("Language params");

  // const { data, loading, error, reFetch } = useFetch(
  //   `https://sisdeve.com/dev/altayar/home`
  // );

 

  return (
    <>
      {/* {loading ? (
        <Loading />
      ) : (
        <div className="App home_body">
          <TopHeader settings={data.settings}/>
          <div className="main-wrapper home_wrap">
            <Header menus={data.menus}/>
            <Offcanvas />
            <Middle />
            <BackToTop />
          </div>
          <Footer />
        </div>
      )} */}
      
      <Middle />

    </>
  );
}

export default Home;
