const initialState = {
  language: "English",
  suffix: "",
  pathName: "",
  headding: "",
  subheadding: "",
  paragraph: ""
};

const favoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
        suffix: "Arbic" === action.payload ? "_arb" : "",
      };
    case "DETECT_LOCATION":
      return {
        ...state,
        pathName: action.payload,
      };
      case "DETECT_HEADING":
        return {
          ...state,
          headding: action.payload,
        };

        case "DETECT_SUBHEADING":
        return {
          ...state,
          subheadding: action.payload,
        };

        case "DETECT_PARAGRAPH":
          return {
            ...state,
            paragraph: action.payload,
          };
  


    default:
      return state;
  }
};

export default favoritesReducer;
