import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { detectPath } from "../actions/favoritesActions";
import Contactus from "../pages/Contactus/Contactus";
import Details from "../pages/DetailsPage/Details";
import Home from "../pages/Home/Home";
import Listing from "../pages/Listing/Listing";
import Propertymanagement from "../pages/Services/Propertymanagement";
import Restatebrokerage from "../pages/Services/Restatebrokerage";
import Valuation from "../pages/Services/Valuation";

export default function Layout() {
  const location = useLocation();
  // console.log("inner location", location);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(detectPath(location.pathname));
    //console.log("inner location", location.pathname);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/listing" element={<Listing />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/itemdetails/:id" element={<Details />} />
        <Route path="/real-estate-brokerage" element={<Restatebrokerage />} />
        <Route path="/property-management" element={<Propertymanagement />} />
        <Route path="/valuation" element={<Valuation />} />
      </Routes>
    </>
  );
}
