import { useState } from "react";
//import BackToTop from "../../components/BackToTop/BackToTap";
import Contact from "../../components/Contact/Contact";
//import Footer from "../../components/Footer/Footer";
//import Header from "../../components/Header/Header";
//import Offcanvas from "../../components/Offcanvas/Offcanvas";
//import TopHeader2 from "../../components/TopHeader2/TopHeader2";
//import useFetch from "../hooks/useFetch2";

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";
import { useEffect } from "react";


function Contactus() {

  const fetchLang = useSelector(state => state.favorites.language);
  const getLanguage = fetchLang;
  //console.log("Contact Fetch language", fetchLang);
  const languageidVal = (fetchLang=='English') ? "1":"2";
  const conttitle = (fetchLang=='English') ? 'Contact Us' : "اتصل بنا";


   // Set language from localStorage on app load
   useEffect(() => {
    //const fetchLang = useSelector(state => state.favorites.language);
    const getLanguage = fetchLang;
    console.log("Contact Fetch language", fetchLang);
    //const languageidVal = (fetchLang=='English') ? "1":"2";
    const conttitle = (fetchLang=='English') ? 'Contact Us' : "اتصل بنا";
  }, [fetchLang]);


  return (
    <>
        <main>
          {/* banner section start */}
          <section className="details_banner">
            <img src="images/details_bg.jpg" alt="" />
            <div className="container">
              <div className="row">
                <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>{conttitle}</h2>
              </div>
            </div>
          </section>
        
          <Contact />
      </main>
    </>
  );
}

export default Contactus;
