//import "./App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Provider } from "react-redux";
import store from "./store/store";
import Listing from "./pages/Listing/Listing";
import Contactus from "./pages/Contactus/Contactus";
import Details from "./pages/DetailsPage/Details";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Restatebrokerage from "./pages/Services/Restatebrokerage";
import Propertymanagement from "./pages/Services/Propertymanagement";
import Valuation from "./pages/Services/Valuation";

import { setLanguage } from "./actions/favoritesActions";

import Header from "./components/Header/Header";
import TopHeader from "./components/TopHeader/TopHeader";
import Offcanvas from "./components/Offcanvas/Offcanvas";
import BackToTop from "./components/BackToTop/BackToTap";
import Footer from "./components/Footer/Footer";
import useFetch from "./pages/hooks/useFetch2";
import Loading from "./components/Loading/Loading";
import TopHeader2 from "./components/TopHeader2/TopHeader2";

import { useLocation } from "react-router-dom";
import Layout from "./layout/Layout";

///Import Captcha
import ReCAPTCHA from "react-google-recaptcha";


function App() {
  const fetchLang = useSelector((state) => state.favorites.language);
  //console.log("Fetch language in APP", fetchLang);
  const getLanguage = fetchLang;
  const languageidVal = fetchLang == "English" ? "1" : "2";
  //console.log("Get language id", languageidVal);

  // fetch Haedding from store
  // const getHaeding = useSelector((state) => state.favorites.headding);
  // console.log("Heading on app page===========>", getHaeding);



  // const { data, loading, error, reFetch } = useFetch(
  //   `https://sisdeve.com/dev/altayar/home`
  // );


  const { data, loading, error, reFetch } = useFetch(
    `https://altayarproperties.om/admin/home`
  );

  //console.log("App menu", data.menus);
  //console.log("Get language id", languageidVal);

  const menuItems1 = data?.menus?.filter(function (el) {
    return el.language_id == languageidVal;
  });
  //console.log("Menu for English", menuItems1);

  
  const getLanValue = localStorage.getItem('detect_lang_val');
  //console.log("Detect Language on APP Page", getLanValue);
  const getInnerLanVal = localStorage.getItem('detect_inner_lang_val');
  //console.log("Inner lang val", getInnerLanVal);
  //console.log("Language in inner page localstorage", getInnerLanVal);
  

  // fetch pathname from store
  const innerPathName = useSelector((state) => state.favorites.pathName);
  //console.log("inner location on app page===========>", innerPathName);


  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={`App ${innerPathName == "/home" || innerPathName == "/"?"home_body":""}`}>
          {innerPathName == "/home" || innerPathName == "/" ? (
            <TopHeader settings={data.settings} />
          ) : (
            <TopHeader2 settings={data.settings} />
          )}
          <div className="main-wrapper home_wrap">
            <Header menus={data.menus} />
            <Offcanvas />
            <Router>
              {/* <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/listing" element={<Listing />} />
                <Route path="/contact-us" element={<Contactus />} />
                <Route path="/itemdetails/:id" element={<Details />} />
                <Route path="/real-estate-brokerage" element={<Restatebrokerage />} />
                <Route path="/property-management" element={<Propertymanagement />} />
                <Route path="/valuation" element={<Valuation />} />
              </Routes> */}
              <Layout />
            </Router>
            <BackToTop />
          </div>
          <div className="cpatcha">
            <ReCAPTCHA sitekey="6LfDwYUqAAAAAAgH7lUbRz-TBnsnN4ULtx5682c9" />
          </div>
          <Footer />
        </div>
      )}
       
      
      
           



    </>
  );
}

export default App;
