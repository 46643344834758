import axios from "axios";
import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";
import useFetch from "../../pages/hooks/useFetch2";


function Contact() {
  const { data, loading, error, reFetch } = useFetch(
    `https://altayarproperties.om/admin/home`
  );

  const allsettings_data = data?.settings;
  console.log("Settings data", data?.settings);

  const fetchLang = useSelector(state => state.favorites.language);
  const getLanguage = fetchLang;
  console.log("Contact Fetch language", fetchLang);
  const languageidVal = (fetchLang=='English') ? "1":"2";
  //console.log("Get language id", languageidVal);


  const formtitlehead = (fetchLang=='English') ? "Fill the form below to contact us for listing your property":"املأ النموذج أدناه للاتصال بنا لإدراج الممتلكات الخاصة بك";
  const formname = (fetchLang=='English') ? "Name":"اسم";
  const formemail = (fetchLang=='English') ? "Email":"بريد إلكتروني";
  const formphone = (fetchLang=='English') ? "Phone":"هاتف";
  const formnadress = (fetchLang=='English') ? "Address":"عنوان";
  const formmessage = (fetchLang=='English') ? "Tell us about your property":"أخبرنا عن الممتلكات الخاصة بك";
  const formpurpose = (fetchLang=='English') ? "Select Purpose":"حدد الغرض";
  const formoption1 = (fetchLang=='English') ? "Landlord":"المالك";
  const formoption2 = (fetchLang=='English') ? "General Inquiry":"استفسار عام";
  const submitbut = (fetchLang=='English') ? "Submit":"يُقدِّم";

  const whatsapp = (fetchLang=='English') ? "Whatsapp:":"واتس اب:";
  const footeraddr = (fetchLang=='English') ? allsettings_data?.site_address:allsettings_data?.site_address_arb;
  const foterAdd = (fetchLang=='English') ? "Address:":"عنوان:";
  const footerphone = (fetchLang=='English') ? "Phone:":"هاتف:";
  const phone_num = (fetchLang=='English') ? allsettings_data?.site_phone_1 : allsettings_data?.site_phone_1_arb;
 
   const [username, setUsername] = useState("");
   const [useremail, setUseremail] = useState("");
   const [userphone, setUserphone] = useState("");
   const [useraddress, setUserAddress] = useState("");
   const [userpurpose, setPurpose] = useState("");
   const [usermessage, setMessage] = useState("");

  // const [user, setUser] = useState({
  //   user_name: " ",
  //   user_email: " ",
  //   user_phone: " ",
  //   user_address: " ",
  //   user_message: " ",
  // });
  // const[errors,setErrors]=useState('')

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUser({
  //     ...user,
  //     [name]: value,
  //   });
  //   console.log(user)
  // };

  
  
  const handlesubmit = async(event)=>{
    event.preventDefault();
    let userDetails = {
      username: username,
      useremail: useremail,
      userphone: userphone,
      useraddress: useraddress,
      userpurpose: userpurpose,
      usermessage: usermessage,
    };
    
    //const apiUrl = '/home/contact';
    //const apiUrl = 'https://sisdeve.com/dev/altayar/home/contact';
    const apiUrl = 'https://altayarproperties.om/admin/home/contact';
    const contactPost = axios.post(apiUrl, 
        {
          "name" : username,
          "phone" : userphone,
          "email" : useremail,
          "address" : useraddress,
          "inquiry" : userpurpose,
          "message" : usermessage
        });
    // const responseData = contactPost.response;
    // console.log("Contact response", responseData.status);

     Swal.fire({
      icon: "success",
      title: "Success",
      text: "Thank You for submitting. We will contact soon!", 
    });
    setUsername("");
    setUseremail("");
    setUserphone("");
    setPurpose("");
    setUserAddress("");
    setMessage("");
    


    // try{
    // // const res= await axios.post(`url`,user)
    // // console.log(res);
    // Swal.fire({
    //   icon: "success",
    //   title: "Success",
    //   text: "Thank You!", 
    // });


    // }catch(err){
    //    setErrors(err)
    //    console.log(err);
    // }
  }


  return (
    <>
<section className="contact_form">
  <div className="container">
    <div className="contact_card cnct_page">
      <h3 className={`${fetchLang == "English" ?"": "_heading"}`}>{formtitlehead}</h3>
      <div className="row flex-column-reverse flex-md-row">
        <div className="col-md-7">
        <form name="contactform" id="contactform" onSubmit={handlesubmit}>
          <div className="row">
            <div className="col-md-6">
              <input type="text" className={`${fetchLang == "English" ?"": "_paragraph"}`} placeholder={formname} required name="user_name"
                  value={username}
                  onChange={(e)=> {
                      setUsername(e.target.value)
                  }} />
            </div>
            <div className="col-md-6">
                <input className={`${fetchLang == "English" ?"": "_paragraph"}`} type="email" name="user_email"
                  value={useremail}
                  onChange={(e) => {
                       setUseremail(e.target.value)
                  }}
                  placeholder={formemail}
                  required />
            </div>
            <div className="col-md-6">
              <input className={`${fetchLang == "English" ?"": "_paragraph"}`} name="user_phone"
                  onChange={(e) => {
                      const phone = e.target.value;
                      // Remove any non-numeric characters
                       const onlyNumbers = phone.replace(/\D/g, "");
                      setUserphone(onlyNumbers)
                  }}
                  type="tel"
                  value={userphone}
                  placeholder={formphone}
                  maxLength={10}
                  minLength={10}
                  required />
            </div>
            <div className="col-md-6">
              <input className={`${fetchLang == "English" ?"": "_paragraph"}`} type="text" name="user_address"
                  onChange={(e) => {
                      setUserAddress(e.target.value)
                  }}
                  value={useraddress}
                  placeholder={formnadress}
                  required />
            </div>
            <div className="col-md-12">
              <select
                name="purpose"
                id="purpose"
                required
                className={`custom-select ${fetchLang == "English" ?"": "_paragraph"}`}
                value={userpurpose} onChange={(e)=>{
                  setPurpose(e.target.value);
                }} 
              >
                <option value="">{formpurpose}</option>
                <option value="Landlord">{formoption1}</option>
                <option value="General Inquiry">{formoption2}</option>
              </select>
            </div>
            <div className="col-md-12">
              <textarea
                className={`${fetchLang == "English" ?"": "_paragraph"}`}
                name="user_message"
                onChange={(e) => {
                     setMessage(e.target.value)
                }}
                placeholder={formmessage}
                value={usermessage}
                required
              />
            </div>
          </div>
          <button className={`btn con_sub_btn ${fetchLang == "English" ?"": "_paragraph"}`}>{submitbut}</button>
          </form>
        </div>
        
        <div className="col-md-5">
          <div className="location_box">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.371588480355!2d58.19769669999999!3d23.626860000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8dfd91dfd60991%3A0x321f025f2376e46b!2zQWxUYXlhciBCdWlsZGluZyAtINmF2KjZhtmJINin2YTYt9mK2KfYsSAoS0NCKQ!5e0!3m2!1sen!2sin!4v1726813717020!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="bottom_links">
      <div className="row">
        <div className="col-md-4">
          <div className="link_box">
            <i className="fa-solid fa-route" />
            <div className="content">
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                <b>{foterAdd} </b> {footeraddr}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="link_box">
            <a href="tel:+968 - 24188858"><i className="fa-solid fa-phone" /></a>
            <div className="content">
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                <b>{footerphone} </b>{phone_num}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="link_box">
            <a href="https://wa.me/96891424242" target="_blank"><i className="fa-brands fa-whatsapp" /></a>
            <div className="content">
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                <b>{whatsapp} </b>{phone_num}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  );
}

export default Contact;
