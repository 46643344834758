import React, { useState } from "react";
import Offcanvas from "../Offcanvas/Offcanvas";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";


function Header({ menus }) {
  //console.log("Header", typeof menus);
  //console.log("Haeder Array", Array.isArray(menus));
  //console.log("Detect language", useSelector(state => state.favorites.language));
  const fetchLang = useSelector(state => state.favorites.language);
  const getLanguage = fetchLang;
  //console.log("Fetch language", fetchLang);
  const languageidVal = (fetchLang=='English') ? "1":"2";
  //console.log("Get language id", languageidVal);

  const menuItems1= menus?.filter(function(el){
        return el.language_id==languageidVal;
  })
  //console.log("Menu for English", menuItems1);

  // Sort by 'id' in ascending order
  const menuItems = menuItems1?.sort((a, b) => a.id - b.id);
  //console.log("Menu sort order", menuItems);

  return (
    <>
      <div id="header" className="section header-section header-section-05">
        <div className="container-fluid">
          {/* Header Wrap Start  */}
          <div className="header-wrap">
            <div className="header-menu d-none d-lg-block">
              <ul className="main-menu">
                {menuItems?.map(menudata =>{
                      return <li key={menudata.id} className={menudata.menu_name === "Home" ? "active-menu" : ""}>
                            {(menudata.menu_slug!='services') ? <a  href={`#/${menudata.menu_slug}`} className={`${fetchLang == "English" ?"": "_paragraph"}`}>{menudata.menu_name}</a>:<a  href="#" className={`${fetchLang == "English" ?"": "_paragraph"}`}>{menudata.menu_name}</a>}
                              {menudata.submenu.length >0 && (
                                <ul className="sub-menu">
                                  {menudata.submenu.map(subItem => (
                                    <li key={subItem.submenu_id}>
                                      <a href={`#/${subItem.submenu_slug}`} className={`${fetchLang == "English" ?"": "_paragraph"}`}>{subItem.submenu_name}</a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                      </li>;
                })}
              </ul> 

            {(fetchLang=='English') ? (
            <button class="adv_btn">
                <a href="#/listing" className={`${fetchLang == "English" ?"": "_paragraph"}`} style={{ textDecoration: "none", color: "inherit" }}>Advance Search</a>
            </button>): <button class="adv_btn">
                  <a href="#/listing"
                    style={{ textDecoration: "none", color: "inherit" }} className={`${fetchLang == "English" ?"": "_paragraph"}`}
                  >
                    بحث متقدم
                  </a>
            </button>}


            </div>

            <div className="header-meta">
              {/* Header Toggle Start */}
              <div className="header-toggle d-lg-none">
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                >
                  <span />
                  <span />
                  <span />
                </button>
              </div>
              {/* Header Toggle End */}
            </div>
            {/* Header Meta End  */}
          </div>
          {/* Header Wrap End  */}
        </div>
      </div>
    </>
  );
}

export default Header;
