import { useEffect, useState } from "react";
import axios from "axios";

const useFetch = (url, searchValue, getlanguageID) => {
  console.log("Api url", url);
  console.log("Search Value", searchValue);
  console.log("Language id in Hook", getlanguageID);
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(url, {item_name: searchValue, language_id : getlanguageID});
        setData(res.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetchData();
  }, [url,getlanguageID]);

  const reFetch = async () => {
    setLoading(true);
    try {
      const res = await axios.post(url);
      setData(res.data);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { data, loading, error, reFetch };
};

export default useFetch;