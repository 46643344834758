import React from "react";
import Swal from "sweetalert2";
//import BackToTop from "../../components/BackToTop/BackToTap";
//import Footer from "../../components/Footer/Footer";
//import Header from "../../components/Header/Header";
//import Offcanvas from "../../components/Offcanvas/Offcanvas";
//import TopHeader from "../../components/TopHeader/TopHeader";
//import TopHeader2 from "../../components/TopHeader2/TopHeader2";

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../actions/favoritesActions";
import { useEffect } from "react";
import axios from "axios";

function Valuation() {
  const [pageTitle, setpageTitle] = useState("");
  //const [desc1title1, setDesc1Title1] = useState([]);
  //const [desc2title2, setDesc2Title2] = useState([]);
  //const [desc3title3, setDesc3Title3] = useState("");
  const [desc3icon, setdesc3icon] = useState("");

  const [alldata, setAlldata] = useState([]);
  const [serviceDetails, setServicedetails] = useState([]);

  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [userphone, setUserphone] = useState("");
  const [useraddress, setUserAddress] = useState("");
  const [usermessage, setMessage] = useState("");

  const fetchLang = useSelector((state) => state.favorites.language);
  console.log("Fetch language in Resatate", fetchLang);
  const getLanguage = fetchLang;
  const languageidVal = fetchLang == "English" ? "1" : "2";
  const request_valuation_title =
    fetchLang == "English"
      ? "Request a valuation of your property"
      : "اطلب تقييم الممتلكات الخاصة بك";
  const vname = fetchLang == "English" ? "Name" : "اسم";
  const vemail = fetchLang == "English" ? "Email" : "بريد إلكتروني";
  const vphone = fetchLang == "English" ? "Phone" : "هاتف";
  const vAddress = fetchLang == "English" ? "Property Address" : "عنوان العقار";
  const vmessage = fetchLang == "English" ? "Message" : "رسالة";
  const sendButton = fetchLang == "English" ? "Send" : "يرسل";

  const desc1title1 =
    fetchLang == "English"
      ? "When it comes to property valuation, trust Altayar Properties to provide accurate and reliable assessments."
      : "عندما يتعلق الأمر بتقييم العقارات، ثق في شركة الطيار العقارية لتقديم تقييمات دقيقة وموثوقة.";
  const desc2title2 =
    fetchLang == "English"
      ? "Here's why our valuation services stand out:"
      : "هذا هو سبب تميز خدمات التقييم لدينا:";
  const desc3title3 =
    fetchLang == "English"
      ? "Trust Altayar Properties for accurate, timely, and reliable property valuations that empower your real estate decisions."
      : "ثق بشركة الطيار العقارية للحصول على تقييمات عقارية دقيقة وفي الوقت المناسب وموثوقة والتي تمكنك من اتخاذ قراراتك العقارية.";

  const data = {
    service_slug: "valuation",
    language_id: languageidVal,
  };

  //const apiUrl = "/services-show-by-slug";
  //const apiUrl = 'https://sisdeve.com/dev/altayar/services-show-by-slug';
  const apiUrl = 'https://altayarproperties.om/admin/services-show-by-slug';
  useEffect(() => {
    // Define the async function inside the useEffect
    const postData = async () => {
      try {
        const response = await axios.post(apiUrl, {
          service_slug: "valuation",
          language_id: languageidVal,
        });
        console.log("API Response:", response.data);
        const alldata = response.data;
        setAlldata(response.data);
        const pageTitle = alldata.submenu_details?.submenu_name;
        setpageTitle(pageTitle);
        //console.log("Page title", pageTitle);
        const desc1 = alldata.submenu_details?.submenu_description_1;
        console.log("Desc1", desc1);
        //const desc1_title1 = desc1.title;
        //setDesc1Title1(desc1);

        const desc2 = alldata.submenu_details?.submenu_description_2;
        console.log("Desc1", desc2);
        //const desc1_title1 = desc1.title;
        //setDesc2Title2(desc2);

        const desc3 = alldata.submenu_details?.submenu_description_3;
        //setDesc3Title3(desc3);

        const desc3icon = alldata?.submenu_details?.submenu_icon;
        //console.log("Service details", serviceDetails);
        setdesc3icon(desc3icon);

        const serviceDetailsdata = alldata?.submenu_details?.service_details;
        console.log("Service details", serviceDetailsdata);
        setServicedetails(serviceDetailsdata);
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    // Call the function to execute the POST request
    postData();
  }, [languageidVal]); // Empty dependency array ensures the API call runs only once when the component mounts

  const handleSubmit = async (event) => {
    event.preventDefault();
    let userDetails = {
      username: username,
      useremail: useremail,
      userphone: userphone,
      useraddress: useraddress,
      usermessage: usermessage,
    };

    //const apiUrl = '/valuation-inquiry';
    //const apiUrl = "https://sisdeve.com/dev/altayar/valuation-inquiry";
    const apiUrl = "https://altayarproperties.om/admin/valuation-inquiry";
    const contactPost = axios.post(apiUrl, {
      name: username,
      phone: userphone,
      email: useremail,
      address: useraddress,
      message: usermessage,
    });
    // const responseData = contactPost.response;
    // console.log("Contact response", responseData.status);

    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Thank You for submitting the Request. We will contact soon!",
    });
    setUsername("");
    setUseremail("");
    setUserphone("");
    setUserAddress("");
    setMessage("");
  };

  const stripHtmlTags = (str) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  return (
    <>
      <main>
        {/* banner section start */}
        <section className="banner inn">
          <div
            className="banner_bg"
            style={{ backgroundImage: "url(images/details_bg.jpg)" }}
          >
            <h2 className={`${fetchLang == "English" ? "" : "_heading"}`}>
              {pageTitle}
            </h2>
          </div>
        </section>
        {/* banner section end */}
        {/* cards section start */}
        <section className="card_section_poperty py-5 in_valuation">
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <p
                  className={`value_sub ${
                    fetchLang == "English" ? "" : "_paragraph"
                  }`}
                >
                  {desc1title1}
                </p>
                <p
                  className={`underline ${
                    fetchLang == "English" ? "" : "_paragraph"
                  }`}
                >
                  {desc2title2}
                </p>
              </div>

              <div className="col-md-9 ">
                <div className="row in_row">
                  {serviceDetails?.map((servilist) => (
                    <div className="col-md-4 mb-5 px-4">
                      <div className="card_box">
                        <div className="img_box">
                          <img src={servilist?.service_icon} alt="" />
                        </div>
                        <div className="card_text mt-2">
                          <h3
                            className={`highlight ${
                              fetchLang == "English" ? "" : "_heading"
                            }`}
                          >
                            {servilist?.service_title}
                          </h3>
                          <p
                            className={`${
                              fetchLang == "English" ? "" : "_paragraph"
                            }`}
                          >
                            {stripHtmlTags(servilist?.service_description)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-3">
                <form name="property_valation_form" onSubmit={handleSubmit}>
                  <div className="prop_details">
                    <h4
                      className={`${fetchLang == "English" ? "" : "_heading"}`}
                    >
                      {request_valuation_title}
                    </h4>
                    <input
                      type="text"
                      className={`${
                        fetchLang == "English" ? "" : "_paragraph"
                      }`}
                      required
                      placeholder={vname}
                      name="user_name"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                    <input
                      className={`${
                        fetchLang == "English" ? "" : "_paragraph"
                      }`}
                      type="email"
                      required
                      placeholder={vemail}
                      name="user_email"
                      value={useremail}
                      onChange={(e) => {
                        setUseremail(e.target.value);
                      }}
                    />

                    <input
                      type="tel"
                      className={`${
                        fetchLang == "English" ? "" : "_paragraph"
                      }`}
                      required
                      placeholder={vphone}
                      onChange={(e) => {
                        const phone = e.target.value;
                        // Remove any non-numeric characters
                        const onlyNumbers = phone.replace(/\D/g, "");
                        setUserphone(onlyNumbers);
                      }}
                      value={userphone}
                      maxLength={10}
                      minLength={10}
                    />

                    <input
                      className={`${
                        fetchLang == "English" ? "" : "_paragraph"
                      }`}
                      type="text"
                      required
                      placeholder={vAddress}
                      name="user_address"
                      onChange={(e) => {
                        setUserAddress(e.target.value);
                      }}
                      value={useraddress}
                    />
                    <textarea
                      className={`${
                        fetchLang == "English" ? "" : "_paragraph"
                      }`}
                      required
                      placeholder={vmessage}
                      name="user_message"
                      value={usermessage}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                    <button
                      className={`btn frm_btn ${
                        fetchLang == "English" ? "" : "_paragraph"
                      }`}
                    >
                      {sendButton}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-12 px-0">
                <div className="bot_text py-2">
                  <p
                    className={`highlight text-center px-3 ${
                      fetchLang == "English" ? "" : "_paragraph"
                    }`}
                  >
                    {desc3title3}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* cards section end */}
      </main>
    </>
  );
}

export default Valuation;
