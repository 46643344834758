import React from "react";
import axios from "axios";
import { useState } from "react";
//import BackToTop from "../../components/BackToTop/BackToTap";
import Card from "../../components/Card/Card";
//import Footer from "../../components/Footer/Footer";
//import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
//import Offcanvas from "../../components/Offcanvas/Offcanvas";
//import Search from "../../components/Search/Search";
//import TopHeader2 from "../../components/TopHeader2/TopHeader2";
//import Landlord from "../Landlord/Landlord";
import useFetch from "../hooks/useFetch1";
import { useLocation } from 'react-router-dom';


import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";



function Listing() {
  
  const fetchLang = useSelector(state => state.favorites.language);
  console.log("Fetch language in listing page", fetchLang);
  const getLanguage = fetchLang;
  const languageidVal = (fetchLang=='English') ? "1":"2";
  console.log("Fetch langauage id in listing page", languageidVal);

  const location = useLocation();
  const searchQuery = location.state?.searchQuery;
  //const apiUrl = '/home/search';
  //const apiUrl = 'https://sisdeve.com/dev/altayar/home/search';
  const apiUrl = 'https://altayarproperties.om/admin/home/search';

  const { data, loading, error, resfetch } = useFetch(apiUrl, searchQuery,languageidVal);
  console.log("All data", data);
  console.log("list data", data?.["data"]?.[1]);
  //const getlistData = data?.["data"]?.[1];

  ////For Advanced Search here
   const [getSearchClickVal, setCheckSearchFormClick] = useState(0);
   const [locationval, setLocation] = useState("");
   const [saleRent, setSaleRent] = useState("");
   const [ptyval, setPtypeVal] = useState("");
   const [frompriceVal, setFromPrice] = useState("");
   const [topriceVal, setToPrice] = useState("");
   const [ressearchdata, setResSearchData] = useState([]);

   ///Arabic text convert
   const findyourdream = (fetchLang=='English') ? "Find Your Dream Property Today !" : "ابحث عن عقار أحلامك اليوم!";
   const area = (fetchLang =='English') ? "AREA" : "منطقة";
   const salerent = (fetchLang =='English') ? "SALE/RENT" : "بيع / إيجار";
   const protype = (fetchLang =='English') ? "PROPERTY TYPE" : "نوع العقار";
   const priceomr = (fetchLang =='English') ? "PRICE (OMR)" : "السعر (ر.ع)";
   const location_place = (fetchLang == 'English') ? "Enter Location" : "أدخل الموقع";
   const selectdrop = (fetchLang=='English') ? "Select" : "يختار";
   const saleoption = (fetchLang == 'English') ? "Sale" : "أُوكَازيُون";
   const rentoption = (fetchLang == 'English') ? "Rent" : "إيجار";
   const appartment = (fetchLang == 'English') ? "Apartment" : "شقة";
   const clerbutton = (fetchLang =='English') ? "Clear" : "واضح";
   const advancedbutton = (fetchLang =='English') ? "Advanced" : "متقدم";
   const norecord = (fetchLang == 'English') ? "Oops sorry, no property found." : "عفوًا، آسف، لم يتم العثور على أي عقار.";
   const foundrec = (fetchLang == 'English') ? "found" : "وجد"; 

   const advaned_search_mes = (fetchLang == 'English') ? "Oops sorry, no property found on your searched area! You can search different area OR use the above  filters for better results." : "عفوًا، آسف، لم يتم العثور على أي عقار في المنطقة التي بحثت عنها! يمكنك البحث في منطقة مختلفة أو استخدام المرشحات المذكورة أعلاه للحصول على نتائج أفضل.";
   const sortby = (fetchLang=='English') ? "Short by:" : "باختصار بواسطة:";
   const newest = (fetchLang=='English') ? "Newest" : "الأحدث";
   const popoular = (fetchLang=='English') ? "Popoular" : "شائع";
   const price_hl = (fetchLang=='English') ? "PRICE (HIGH TO LOW)" : "السعر (من الأعلى إلى الأقل)";
   const pricelh = (fetchLang=='English') ? "PRICE (LOW TO HIGH)" : "السعر (من الأقل إلى الأعلى)";

   const price_from = (fetchLang=='English') ? "From" : "من";
   const price_to = (fetchLang=='English') ? "To" : "ل";



  const switchToGridView = () => {
    console.log("switchToGridView");
    const productsElement = document.getElementById("products");
    productsElement.classList.remove("list-view");
    productsElement.classList.remove("mini-grid-view");
    productsElement.classList.add("grid-view");
  };

  const switchToMiniGridView = () => {
    console.log("switchToMiniGridView");
    const productsElement = document.getElementById("products");
    productsElement.classList.remove("list-view");
    productsElement.classList.remove("grid-view");
    productsElement.classList.add("mini-grid-view");
  };

  const switchToListView = () => {
    console.log("switchToListView");
    const productsElement = document.getElementById("products");
    productsElement.classList.remove("grid-view");
    productsElement.classList.remove("mini-grid-view");
    productsElement.classList.add("list-view");
  };



  const advsearch = async(e) => {
      console.log("Adv button clicked");
      if(locationval==""){
         alert("Please enter location.");
         document.getElementById("location_area").focus();
         return true;
      }
      setCheckSearchFormClick(1);
      let searchData = {
         "location": locationval,
         "saleRent" : saleRent,
         "property-type": ptyval,
         "from_price": frompriceVal,
         "to_price": topriceVal

      }
     //console.log("Advanced serach data", searchData); return;
     console.log("Search data", searchData);
    
     //const apiUrl = '/item-search';
     //const apiUrl = 'https://sisdeve.com/dev/altayar/item-search';
     const apiUrl = 'https://altayarproperties.om/admin/item-search';
     const response = await axios.post(apiUrl, {
            "location": locationval,
            "type" : saleRent,
            "category": ptyval,
            "form_price": frompriceVal,
            "to_price": topriceVal,
            "language_id" : languageidVal
      })
      setResSearchData(response.data);
      // setLocation("");
      // setSaleRent("");
      // setPtypeVal("");
      // setFromPrice("");
      // setToPrice("");
    
      
  }

  const claerdata = () => {
      //console.log("clear button is clicked");
      setLocation("");
      setSaleRent("");
      setPtypeVal("");
      setFromPrice("");
      setToPrice("");
  }




  return (
    <>
     
        <main>
          {/* <Search /> */}

        {/************Advanced search start here 02.09.2024  */}
        <section className="search_page">
        <div className="left_bg">
          <img src="images/alt_lt.png" alt="" />
        </div>
        <div className="right_bg">
          <img src="images/alt_rt.png" alt="" />
        </div>
        <div className="container">
          <div className="search_card">
            <div className="row">
              <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>{findyourdream}</h2>
              <div className="col-md-3 col-sm-6">
                <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{area}</span>
                <input type="text" id="location_area" className={`${fetchLang == "English" ?"": "_paragraph"}`} value={locationval} placeholder={location_place} onChange={(e) => setLocation(e.target.value)} />
              </div>
              <div className="col-md-3 col-sm-6">
                <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{salerent}</span>
                <select className={`form-control ${fetchLang == "English" ?"": "_paragraph"}`} value={saleRent} onChange={(e) => setSaleRent(e.target.value)}>
                  <option value="">{selectdrop}</option>
                  <option value="Sale">{saleoption}</option>
                  <option value="Rent">{rentoption}</option>
                </select>
              </div>
              <div className="col-md-3 col-sm-6">
                <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{protype}</span>
                <select className={`form-control ${fetchLang == "English" ?"": "_paragraph"}`}  value={ptyval} onChange={(e) => setPtypeVal(e.target.value)}>
                  <option value="">{selectdrop}</option>
                  <option value="Apartment">{appartment}</option>
                </select>
              </div>
              <div className="col-md-3 col-sm-6">
                <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{priceomr}</span>
                <div className="price_ip">
                  <input type="number" className={`${fetchLang == "English" ?"": "_paragraph"}`} placeholder={price_from} value={frompriceVal} onChange={(e) => {setFromPrice(e.target.value)}} />
                  <input type="number" className={`${fetchLang == "English" ?"": "_paragraph"}`} placeholder={price_to} value={topriceVal} onChange={(e) => setToPrice(e.target.value)} />
                </div>
              </div>
              <div className="btn_box d-flex justify-content-center">
                <button  className={`btn _clr ${fetchLang == "English" ?"": "_heading"}`} onClick={claerdata}>{clerbutton}</button>
                <button className={`btn _adv ${fetchLang == "English" ?"": "_heading"}`}  onClick={advsearch}>{advancedbutton}</button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ul className="filter d-flex">
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <span className={`${fetchLang == "English" ?"": "_heading"}`}>{sortby}</span>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <button  className={`btn _filter ${fetchLang == "English" ?"": "_heading"}`}>{newest}</button>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <button className={`btn _filter ${fetchLang == "English" ?"": "_heading"}`}>{popoular}</button>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <button className={`btn _filter ${fetchLang == "English" ?"": "_heading"}`}>{price_hl}</button>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <button className={`btn _filter ${fetchLang == "English" ?"": "_heading"}`}>{pricelh}</button>
                  </li>
                </ul>
                <ul className="mini_filter d-none">
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>Short by:</span>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <select className="form-controls">
                      <option selected="">NEWEST</option>
                      <option value="option 1">POPULAR</option>
                      <option value="option 2">PRICE (HIGH TO LOW)</option>
                      <option value="option 3">PRICE (LOW TO HIGH)</option>
                    </select>
                  </li>
                </ul>
              </div>
              <div className="col-md-2">
                <ul className="grid_switch d-flex justify-content-between align-items-center">
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <button className="btn" onClick={switchToGridView}>
                      <img src="images/grid.png" alt="" />
                    </button>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <button className="btn" onClick={switchToMiniGridView}>
                      <img src="images/minigrid.png" alt="" />
                    </button>
                  </li>
                  <li className={`${fetchLang == "English" ?"": "_paragraph"}`}>
                    <button className="btn" onClick={switchToListView}>
                      <img src="images/list.png" alt="" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/************Advanced search end here 02.09.2024  */}

          {getSearchClickVal==0 ? (
          <section className="product_list">
            <div className="container">
              <div className="row">
                {(data?.["data"]?.[1].length > 0)?(<h2 className={`pb-5 ${fetchLang == "English" ?"": "_heading"}`}>
                  <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>{data?.["data"]?.[1]?.length} {foundrec}</h2>
                </h2>):""}

                {(data?.["data"]?.[1].length == 0)?(<h2 className={`pb-5 ${fetchLang == "English" ?"": "_heading"}`} style={{"margin-bottom":"100px"}}>
                  <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>{norecord}</h2>
                </h2>):""}

                <div id="products" className="grid-view">
                  {loading ? (
                    <Loading />
                  ) : (
                    data?.["data"]?.[1].map((item) => <Card key={item.id} item={item} />)
                  )}
                </div>
              </div>
            </div>
          </section>) : ""}


          {getSearchClickVal==1 ? (
          <section className="product_list">
            <div className="container">
              <div className="row">
                {(ressearchdata?.["data"]?.[1].length > 0)?(<h2 className={`pb-5 ${fetchLang == "English" ?"": "_heading"}`}>
                  <h2 className={`pb-5 ${fetchLang == "English" ?"": "_heading"}`}>{ressearchdata?.["data"]?.[1]?.length} found</h2>
                </h2>):""}

                {(ressearchdata?.["data"]?.[1].length == 0)?(<h2 className={`pb-5 ${fetchLang == "English" ?"": "_heading"}`} style={{"margin-bottom":"100px"}}>
                  <h2 className={`pb-5 ${fetchLang == "English" ?"": "_heading"}`}>{advaned_search_mes}</h2>
                </h2>):""}

                <div id="products" className="grid-view">
                  {loading ? (
                    <Loading />
                  ) : (
                    ressearchdata?.["data"]?.[1].map((item) => <Card key={item.id} item={item} />)
                  )}
                </div>
              </div>
            </div>
          </section>) : ""}
        </main>
       
    </>
  );
}

export default Listing;
