import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";

function Card({ item }) {
  ///Arabic conversation
  const fetchLang = useSelector(state => state.favorites.language);
  //console.log("Fetch language in detail page", fetchLang);
  const languageidVal = (fetchLang=='English') ? "1":"2";
  const omr = (fetchLang=='English') ? "OMR" : "ريال عماني";
  const review  = (fetchLang=='English') ? "Reviews" : "التعليقات";
  const bed = (fetchLang=='English') ? "Beds" : "سرير";
  const bath = (fetchLang=='English') ? "Baths" : "حمامات";
  const listed = (fetchLang=='English') ? "Listed on :" : "مدرج على:";
  const catname = (fetchLang=='English') ? "Category :" : "فئة :";

  return (
    <>
      <div className="product">
        <div className="img_box">
          
          {item.image.map((itemimag) => (
                    (itemimag?.is_thumbnail==1)?(<img src={itemimag?.image_url} alt="" />):""
                ))
          }

          <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>{omr}{item.price}</span>
          {item.is_featured === 1 ? <h5 className={`${fetchLang == "English" ?"": "_heading"}`}>Featured</h5> : null}
          {item.is_new === 1 ? <h6 className={`${fetchLang == "English" ?"": "_heading"}`}>New</h6> : null}
        </div>
        <div className="card_box">
          <div className="rating_box">
            <ul>
              <li>
                <img src="images/star.png" alt="" />
              </li>
              <li>
                <img src="images/star.png" alt="" />
              </li>
              <li>
                <img src="images/star.png" alt="" />
              </li>
              <li>
                <img src="images/star.png" alt="" />
              </li>
              <li>
                <img src="images/star.png" alt="" />
              </li>
            </ul>
            <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>
              {item.review_rate} <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>({item.review_count} {review})</span>
            </p>
          </div>
          
            {/* <a href={`/${id}`}>{item.item_name}</a> */}
            {/* <a href={`/itemdetails/${item.id}`}>{item.item_name}</a> */}
            <a href={`#/itemdetails/${item.id}`} className={`${fetchLang == "English" ?"": "_paragraph"}`}>{item.item_name}</a>
          
          <div className="add d-flex align-items-start">
            <img src="images/location.png" alt="" />
            <span className={`${fetchLang == "English" ?"": "_paragraph"}`}>
              {" "}
              {item.address}
            </span>
          </div>
          <ul className="specs">
            <li>
              <img src="images/bed.png" alt="" />
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>{item.bedrooms} {bed}</p>
            </li>
            <li>
              <img src="images/bath.png" alt="" />
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}> {item.bathrooms} {bath}</p>
            </li>
            <li>
              <img src="images/area.png" alt="" />
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>{item.size}</p>
            </li>
          </ul>
          <ul className="bot_list">
            <li>
              <h6 className={`${fetchLang == "English" ?"": "_heading"}`}>{listed}</h6>
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>{item.created_at.split(" ")[0]}</p>
            </li>
            <li>
              <h6 className={`${fetchLang == "English" ?"": "_heading"}`}>{catname}</h6>
              <p className={`${fetchLang == "English" ?"": "_paragraph"}`}>{item.cat_name}</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Card;
