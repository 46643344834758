import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";
import { detectHeadding } from "../../actions/favoritesActions";
import { detectsubHeadding } from "../../actions/favoritesActions";
import { detectParagraph } from "../../actions/favoritesActions";

const TopHeader = ({ settings }) => {
  const [lan, setLan] = useState(useSelector(state => state.favorites.language));

  const dispatch = useDispatch();
  const suffix = useSelector(state => state.favorites.suffix);

  ///Detect language
  const fetchLang = useSelector(state => state.favorites.language);
  //console.log("detect Language", fetchLang);

  const languageidVal = (fetchLang=='English') ? "1":"2";
  const phone_num = (fetchLang=='English') ? settings?.site_phone_1 : settings?.site_phone_1_arb;

  
  ////Detect Header class
  const [haeding, setHead] = useState(useSelector(state => state.favorites.headding));
  const headerVal = useSelector(state => state.favorites.headding);
  //console.log("Detect header 1", headerVal);


  useEffect(() => {
    const mainCssLink = document.getElementById("main-css");
    //console.log("Main CSSS Link", mainCssLink);
    if (mainCssLink) {
      mainCssLink.href = lan === 'English' ? ("scss/main-ltr.css") : ("scss/main-rtl.css");
      
    }

  }, [lan]);



  const toggleLanguage = () => {
    //console.log("Language Switch", lan);
    const selectedLanguage = (lan === 'English') ? 'Arabic' : 'English';
    setLan(selectedLanguage);
    dispatch(setLanguage(selectedLanguage));
    ///Set Language SET for Arabic Version
    localStorage.setItem('detect_lang_val', lan);
 
  };

  
  //console.log("Top header lanaguage", lan);
  const headingClass = (lan === 'English') ? '' : '_heading';
  const subheadingClass = (lan==='English') ? '' : "_subheading";
  const paragraphClass =  (lan==='English') ? '' : "_paragraph";

  useEffect(() => {
    dispatch(detectHeadding(headingClass));
    dispatch(detectsubHeadding(subheadingClass));
    dispatch(detectParagraph(paragraphClass));
    //console.log("Heading>>>>>>>", headingClass);
  }, [lan]);



  return (
    <>
      <div className="header_top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <ul>
                <li className={`adv_btn ${fetchLang == "English" ?"": "_paragraph"}`}>
                  <a href="tel:+">
                    <img src="images/callIcon.png" alt="" />
                    <span className={`adv_btn ${fetchLang == "English" ?"": "_paragraph"}`}>{phone_num}</span>
                  </a>
                </li>
                <li className={`adv_btn ${fetchLang == "English" ?"": "_paragraph"}`}>
                  <a href="#">
                    <img src="images/emailIcon.png" alt="" />
                    <span className={`adv_btn ${fetchLang == "English" ?"": "_paragraph"}`}>{settings?.[`site_email`]}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="right_translate justify-content-end">
                <li>
                  {/* <button className="btn head_btn">
                    <Link
                      to="/landlord"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      Landlord
                    </Link>
                  </button> */}
                </li>
                <li>
                  <button className="lang_btn" onClick={toggleLanguage}>
                    <img src="images/translation.png" alt="" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
