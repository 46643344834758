
import React, { useState } from "react";
//import Offcanvas from "../Offcanvas/Offcanvas";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useFetch from "../../pages/hooks/useFetch2";

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";


function Offcanvas(menus) {
    //console.log("Convas Menus", menus);
    //const menusArray = Object.values(menus);
    //console.log("Convas type", typeof menusArray);
    //console.log("Convas Array", menusArray);

    const fetchLang = useSelector(state => state.favorites.language);
    //console.log("Fetch language in Offconvas page", fetchLang); 
    const languageidVal = (fetchLang=='English') ? "1":"2";
    //console.log("Get language id in Offcanvas", languageidVal);

    const { data, loading, error, reFetch } = useFetch(
        `https://sisdeve.com/dev/altayar/home`
    );

    const menuItems1 = data?.menus?.filter(function (el) {
        return el.language_id == languageidVal;
    });
   //console.log("Filter menu list", menuItems1);
   const menuItems = menuItems1?.sort((a, b) => a.id - b.id);


    return (
        <>
        <div className="offcanvas offcanvas-start" id="offcanvasExample">
          <div className="offcanvas-header">
            {/* Offcanvas Logo Start */}
            <div className="offcanvas-logo">
              <a href="#">
                <img src="images/logo-white.png" alt="" />
              </a>
            </div>
            {/* Offcanvas Logo End */}
            <button type="button" className="close-btn" data-bs-dismiss="offcanvas">
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
          {/* Offcanvas Body Start */}
          <div className="offcanvas-body">
            <div className="offcanvas-menu">
              <ul className="main-menu">
              {menuItems?.map(menudata =>{
                      return <li key={menudata.id} className={menudata.menu_name === "Home" ? "active-menu" : ""}>
                            {(menudata.menu_slug!='services') ? <a  href={`#/${menudata.menu_slug}`} className={`${fetchLang == "English" ?"": "_paragraph"}`}>{menudata.menu_name}</a>:<><a href="#">{menudata.menu_name}</a><span className="menu-icon"></span><span className="menu-expand"></span></>}
                              {menudata.submenu.length >0 && (
                                <ul className="sub-menu" style={{boxSizing: 'border-box', display:'block'}}>
                                  {menudata.submenu.map(subItem => (
                                    <li key={subItem.submenu_id}>
                                      <a href={`#/${subItem.submenu_slug}`} className={`${fetchLang == "English" ?"": "_paragraph"}`}>{subItem.submenu_name}</a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                      </li>;
                })}


            {(fetchLang=='English') ? (
            <button  className={`adv_btn ${fetchLang == "English" ?"": "_heading"}`}>
                <a href="#/listing" style={{ textDecoration: "none", color: "inherit" }} className={`${fetchLang == "English" ?"": "_paragraph"}`}>Advance Search</a>
            </button>): <button className={`adv_btn ${fetchLang == "English" ?"": "_heading"}`}>
                  <a href="#/listing"
                    style={{ textDecoration: "none", color: "inherit" }}
                    className={`${fetchLang == "English" ?"": "_paragraph"}`}
                  >
                    بحث متقدم
                  </a>
            </button>}    
              </ul>
            </div>
          </div>
          {/* Offcanvas Body End */}
        </div>
        {/* Offcanvas End */}
      </>
      
    )
}

export default Offcanvas;