import React, { useState } from "react";
//import TopHeader2 from "../../components/TopHeader2/TopHeader2";
//import Footer from "../../components/Footer/Footer";
////import Header from "../../components/Header/Header";
import Offcanvas from "../../components/Offcanvas/Offcanvas";
import DetailsSec from "../../components/DetailSec/DetailSec";
//import BackToTop from "../../components/BackToTop/BackToTap";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from "../../actions/favoritesActions";

//import styles from './ItemDetail.module.css';

function Details() {
  const fetchLang = useSelector(state => state.favorites.language);
  console.log("Fetch language in detail page", fetchLang);
  const getLanguage = fetchLang;
  const languageidVal = (fetchLang=='English') ? "1":"2";


  const {id} = useParams();
  console.log("Get Item id",  id);
  const[itenname, setItemname] = useState("");
  
  const itemdetailsfetch = async (e) => {
    try {
        //const apiUrl = '/item-details';
        //const apiUrl = 'https://sisdeve.com/dev/altayar/item-details';
        const apiUrl = 'https://altayarproperties.om/admin/item-details';
        const response = await axios.post(apiUrl, {
          'property_id': id,
          'language_id': languageidVal
        });
        let propertyData = response.data;
        //console.log("Item response", propertyData.data.property[0].id);
        console.log("Item response", propertyData);

        let itemVal = propertyData.data.property[0].item_name;
        setItemname(itemVal);

    } catch (error) {
        console.log(error);
    }
};


useEffect(() => {
  itemdetailsfetch(id);
}, [id, languageidVal]);


  return (
    <>
      
        <main>
          <section className="details_banner">
            <img src="images/details_bg.jpg" alt="" />
            <div className="container">
              <div className="row">
                <h2 className={`${fetchLang == "English" ?"": "_heading"}`}>
                     {itenname}
                </h2>
              </div>
            </div>
          </section>
          <DetailsSec/>
        </main>
      
     

    </>
  );
}

export default Details;
