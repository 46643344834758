// src/reducers/propertyReducer.js

import { SET_SALE, SET_PROPERTY_TYPE, SET_PRICE, SET_AREA } from '../actions/searchAction';

const initialState = {
  sale: '',
  propertyType: '',
  price: '',
  area: ''
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case SET_SALE:
      return {
        ...state,
        sale: action.payload
      };
    case SET_PROPERTY_TYPE:
      return {
        ...state,
        propertyType: action.payload
      };
    case SET_PRICE:
      return {
        ...state,
        price: action.payload
      };
    case SET_AREA:
      return {
        ...state,
        area: action.payload
      };
    default:
      return state;
  }
};

export default searchReducer;
