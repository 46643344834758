function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="footer_text">
              <p>Copyright © 2024 Altayarproperties. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
