// src/actions/propertyActions.js

export const SET_SALE = 'SET_SALE';
export const SET_PROPERTY_TYPE = 'SET_PROPERTY_TYPE';
export const SET_PRICE = 'SET_PRICE';
export const SET_AREA = 'SET_AREA';

export const setSale = (sale) => {
  return {
    type: SET_SALE,
    payload: sale
  };
};

export const setPropertyType = (propertyType) => {
  return {
    type: SET_PROPERTY_TYPE,
    payload: propertyType
  };
};

export const setPrice = (price) => {
  return {
    type: SET_PRICE,
    payload: price
  };
};

export const setArea = (area) => {
  return {
    type: SET_AREA,
    payload: area
  };
};
